import PropTypes from "prop-types"
import React from "react"
import { genColor } from "../utils/color-util"
import OutSideLink from "./outlink"
import "./tag.scss"

export const Tag = ({ title, type, linkMap }) => {
  let titleDOM = title

  if (type === "Concept") {
    const inLink = linkMap[title]
    if (inLink)
      titleDOM = (
        <OutSideLink
          title={title}
          url={`https://medium.com/@odayibasi/${inLink}`}
          isDigitalGarden={title.includes("Examples")}
        />
      )

    return (
      <span
        style={{
          background: `${genColor()}`,
        }}
        className="tag"
      >
        {titleDOM}
      </span>
    )
  }

  return <span></span>
}

Tag.propTypes = {
  title: PropTypes.string,
  inLink: PropTypes.string,
  type: PropTypes.string,
  linkMap: PropTypes.object,
}

Tag.defaultProps = {
  title: ``,
  inLink: undefined,
  type: ``,
  linkMap: {},
}

export function hasArrayElement(arr) {
  return Array.isArray(arr) && arr.length > 0
}

export function flatStoryGroup(storiesGroup) {
  const stories = []
  storiesGroup.forEach(sG => {
    sG.storyPart.forEach(st => {
      stories.push(st)
    })
  })
  return stories
}

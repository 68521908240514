import md5 from "react-native-md5"

export function genColorRandom(str) {
  let str_md5v = md5.str_md5(str)

  let hash = 0
  for (let i = 0; i < str_md5v.length; i++) {
    hash = str_md5v.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = "#"
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    colour += ("00" + value.toString(16)).substr(-2)
  }
  return colour
}

let fnColorPalet = [
  // Base Colors (modified from original)
  "#393b79", // Deep Indigo (Base 1)
  "#637939", // Dark Olive Green (Base 2)
  "#8c6d31", // Dark Brown (Base 3)
  "#843c39", // Dark Red-Brown (Base 4)
  "#7b4173", // Dark Purple (Base 5)

  // Variations of Indigo (Base 1)
  "#5254a3", // Lighter Indigo
  "#9c9ede", // Very Light Indigo
  "#282a50", // Darker Indigo
  "#4a4d95", // Medium Dark Indigo

  // Variations of Olive Green (Base 2)
  "#8ca252", // Medium Olive Green
  "#cedb9c", // Very Light Olive Green
  "#4f5e2d", // Darker Olive Green
  "#a2b97a", // Light medium Olive Green

  // Variations of Brown (Base 3)
  "#bd9e39", // Medium Brown
  "#e7cb94", // Very Light Brown/Beige
  "#6e5926", // Darker Brown
  "#d0b55a", // light medium brown

  // Variations of Red-Brown (Base 4)
  "#ad494a", // Medium Red-Brown
  "#e7969c", // Very Light Pink
  "#662e2f", // Darker Red-Brown
  "#c96a6b", // Medium light Red-Brown

  // Variations of Purple (Base 5)
  "#a55194", // Medium Purple
  "#de9ed6", // Very Light Purple
  "#5e3357", // Darker Purple
  "#c27bb6", // Medium light Purple

  // Added complementary and Analogous colors.
  "#ffbb78", // light orange (complementary to Indigo)
  "#a8ddb5", // Light green (analogous to olive)
  "#e377c2", // Pink (From the d3 set, useful for emphasis)
  "#17becf", // Cyan (Useful for contrast)
]

export function genColor() {
  let colorIndex = Math.floor(Math.random() * Math.floor(fnColorPalet.length))
  return fnColorPalet[colorIndex]
}
